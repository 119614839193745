import React from 'react';
import styled from 'styled-components';
import { colors, styles } from 'midtype-backend';

import Layout from '../layout/Layout';
import Arrow from '../icons/Arrow';
import Image from '../components/Image';

const Styled = styled.article`
  padding: 50px;
  margin: auto;
  text-align: center;
  .text-container {
    max-width: 60rem;
    margin: auto;
  }
  .bios {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem;
    margin: 8rem auto 10rem auto;
    max-width: 100rem;
  }
  @media screen and (max-width: 1024px) {
    padding: 30px;
  }
  @media screen and (max-width: 768px) {
    padding: 20px;
    .bios {
      grid-template-columns: 1fr;
      margin: 6rem auto;
    }
  }
`;

const StyledBio = styled.div`
  display: flex;
  text-align: left;
  .avatar {
    flex: 0 0 10rem;
    height: 10rem;
    margin-right: 2rem;
    border-radius: 50%;
    overflow: hidden;
    background-color: ${colors.GRAY_2()};
    box-shadow: ${styles.BOX_SHADOW};
  }
  .text a {
    text-decoration: none;
  }
  .text h3 {
    display: flex;
    align-items: center;
  }
  .text svg {
    margin-left: 10px;
    fill: ${colors.PURPLE_DARK()};
    will-change: transform;
    transition: 250ms all;
  }
  .text h3:hover svg {
    transform: translateX(5px);
  }
  @media screen and (max-width: 400px) {
    flex-flow: column;
    align-items: center;
    .avatar {
      width: 10rem;
      margin-right: 0;
      margin-bottom: 2rem;
    }
  }
`;

interface IBioProps {
  avatar: string;
  linkedin: string;
  name: string;
  bio: string;
}

const Bio: React.FC<IBioProps> = props => (
  <StyledBio>
    <div className="avatar">
      <Image src={props.avatar} />
    </div>
    <div className="text">
      <a href={props.linkedin} target="_blank">
        <h3>
          {props.name} <Arrow />
        </h3>
      </a>
      <p>{props.bio}</p>
    </div>
  </StyledBio>
);

const About = () => {
  return (
    <Layout pageTitle="About">
      <Styled>
        <div className="text-container">
          <h1 style={{ marginBottom: 40 }}>
            Launch and iterate on
            <br /> web apps at light speed.
          </h1>
          <p className="large section-description" style={{ marginBottom: 40 }}>
            Building web apps from scratch is way too time consuming. There's
            too many services to glue together. We wanted an augmentable backend
            that came with everything a web app needs: authentication, payments,
            access control, price segmentation, easy integrations, and more. So
            we built Midtype, the fastest way for anyone to get their web app up
            and running.
          </p>
        </div>
        <div className="bios">
          <Bio
            linkedin="https://www.linkedin.com/in/jayisms/"
            avatar="headshots/jay.jpg"
            name="Jay A. Patel"
            bio="Jay most recently founded and managed the team that improved Uber’s incident response times by an order-of-magnitude. Prior to that, he was a core engineer for VMWare Log Insight (early at Pattern Insight, then VMWare). Jay completed his PhD in Computer Science from the University of Illinois at Urbana-Champaign."
          />
          <Bio
            linkedin="https://www.linkedin.com/in/nilkanth-patel-75346134/"
            avatar="headshots/neel.jpg"
            name="Nilkanth Patel"
            bio="Nilkanth began his career working on data visualization projects at The New Yorker and The New York Times. After obtaining M.S. degrees in Computer Science and Journalism from Columbia University, he became a Product Designer at Quantcast and later became the company's first Manager of Frontend Engineering."
          />
        </div>
      </Styled>
    </Layout>
  );
};

export default About;
